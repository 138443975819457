import Vue from "vue";
import WagtailService from "../services/wagtail.service";

export const wagtail = {
  namespaced: true,
  state: {
    mapComments: [],
    rootPage: undefined,
    currentPage: undefined,
    currentChildren: [],
    currentPopup: undefined,
    esriTokens: [],
    newComment: undefined,
    activeTool: "map",
    showLegend: false,
    layerFilter: null,
    layerOptions: {},
    sceneid: "c5c3b634295c4474bfa03e5a0af96f3c",
    webSceneId: "undefined",
    siteStructure: [],
    siteSettings: {},
    showHeader: true,
    accessibilityMode: false,
    setLandingStreamfield: false,
    pageViewed: [],
    stickyItem: undefined,
    minHotspotSize: 0,
    maxHotspotSize: 0,
    VisibleHotspotGroups: [],
    mapPageLightboxReady: false,
    roomGuide: {
      enabled: false,
      childPageIds: [],
      currentStep:0
    }
  },

  actions: {
    getPageLayers({ commit }, mapPageId) {
      return WagtailService.getPageById(mapPageId)
    },
    getComments({ commit }, mapPageId) {
      return WagtailService.getComments(mapPageId).then(
        (response) => {
          commit("getCommentsSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getCommentsFailure");
          return Promise.reject(error);
        }
      );
    },
    placeComment({ state, commit }, screenpoint) {
      let data = {
        lng: screenpoint[0],
        lat: screenpoint[1],
        name: "",
        comment: "",
        sentiment: "happy",
        map: state.currentPage.id,
      };
      commit("addComment", data);
    },

    getEsriTokens({ commit }) {
      return WagtailService.getEsriTokens().then(
        (response) => {
          commit("getEsriTokenSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getEsriTokenFailure");
          return Promise.reject(error);
        }
      );
    },

    getRootPage({ dispatch, commit }, id) {
      return WagtailService.getPage(id).then(
        (response) => {
          commit("getPageSuccess", response.data);
          dispatch("getPageChildren", response.data.id);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getPageFailure");
          return Promise.reject(error);
        }
      );
    },

    getSiteSettings({ commit }) {
      return WagtailService.getSiteSettings().then(
        (response) => {
          commit("getSiteSettingsSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getSiteSettingsFailure");
          return Promise.reject(error);
        }
      );
    },

    getSiteStructure({ commit }) {
      return WagtailService.getSiteStructure().then(
        (response) => {
          commit("getSiteStructureSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getSiteStructureFailure");
          return Promise.reject(error);
        }
      );
    },

    getPage({ dispatch, commit }, id) {
      return WagtailService.getPage(id).then(
        (response) => {
          if (
            response.data.meta.type ===
              "engage_virtualrooms.VirtualRoomContent" ||
            response.data.meta.type === "engage_virtualrooms.PanoramaRoomPopup" ||
            response.data.meta.type === "engage_virtualrooms.ChatBubbleHotspot"

          ) {
            // If trying to load virtual room content, what we actually want to load is the parent virtual room page
            // Then the engagement room page will take care of any additional routing requirements
            return WagtailService.getPageById(
              response.data.meta.parent.id
            ).then(
              (response) => {
                commit("getPageSuccess", response.data);
                dispatch("getPageChildren", response.data.id);
                return Promise.resolve(response.data);
              },
              (error) => {
                commit("getPageFailure");
                return Promise.reject(error);
              }
            );
          } else {
            commit("getPageSuccess", response.data);
            dispatch("getPageChildren", response.data.id);
            return Promise.resolve(response.data);
          }
        },
        (error) => {
          commit("getPageFailure");
          return Promise.reject(error);
        }
      );
    },

    async getPanoramaPage({ dispatch, commit }, id) {
      return WagtailService.getPage(id);
    },

    // TODO: This doesn't really need to be an action and is just a wrapper for calling the wagtail service from a component
    // I can't quite figure out how to make the component successfully call the service without errors, so here we are
    // Future JS wizard, solve this :D
    getPageByUrl(context, url) {
      return WagtailService.getPageByUrl(url).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getPageChildren({ commit }, id) {
      return WagtailService.getPageChildren(id).then(
        (response) => {
          commit("getPageChildrenSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getPageChildrenFailure");
          return Promise.reject(error);
        }
      );
    },

    async getStartPanorama({ commit }, id) {
      return WagtailService.getPageById(id).then(
        (response) => {
          return Promise.resolve(response.data);
        });
    },
    async getPrecinctChildPage({ commit }, id) {
      return WagtailService.getPageById(id).then(
        (response) => {
          return Promise.resolve(response.data);
        });
    },
    async getRenderLocationChildPage({ commit }, id) {
      return WagtailService.getPageById(id).then(
        (response) => {
          return Promise.resolve(response.data);
        });
    },
    async getPageById({commit}, id) {
      return WagtailService.getPageById(id).then(
        (response) => {
          return Promise.resolve(response.data);
        });
    },
    async getPageDetails({ commit }, id) {
      return WagtailService.getPage(id).then(
        (response) => {
          return Promise.resolve(response.data);
        });
    },

    getPopup({ commit }, page_id) {
      return WagtailService.getPageById(page_id).then(
        (response) => {
          commit("getPopupSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getPopupFailure");
          return Promise.reject(error);
        }
      );
    },
    getStickyItem({commit}, page_id){
      return WagtailService.getPageById(page_id).then(
        (response) => {
          commit("getStickySuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getStickyError");
          return Promise.reject(error);
        }
      );
    },

    savePage({ state }) {
      let data = { title: "Hello There" };
      return WagtailService.savePage(state.currentPage.id, data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    setPageTitle({ commit }, data) {
      commit("setPageTitle", data);
    },

    addKeyStat({ commit }, data) {
      commit("addKeyStat", data);
    },
    removeKeyStat({ commit }, index) {
      commit("removeKeyStat", index);
    },
    setAccessibiltyModeFlag({ commit }, value) {
      commit("setAccessibilityMode", value);
    },
    setLandingStreamfieldFlag({ commit }, value) {
      commit("setLandingStreamfield", value);
    },
    setViewedPageFlag({ commit }, value) {
      commit("setViewedPage", value);
    },    
    updateMinHotspotSize({ commit }, newSize) {
      commit('setMinHotspotSizes', newSize);
    },
    updateMaxHotspotSize({ commit }, newSize) {
      commit('setMaxHotspotSizes', newSize);
    },
    setCurrentPopup({commit}, value) {
      commit('getPopupSuccess', value)
    },
    updateMapLayerOptions({commit}, newValue) {
      commit('setLayerOptions', newValue)
    },
    toggleGuideEnabled({ commit, state }) {
      commit('setGuideEnabled', !state.roomGuide.enabled);
    },
    nextStepGuide({ commit, state }) {
      commit('stepGuideEnabled', state)
    },
    addGuideChildPageId({ commit }, pageId) {
      commit('addGuideChild', pageId);
    },
    removeGuideChildPageId({ commit }, pageId) {
      commit('removeGuideChild', pageId);
    },
    replaceGuideChildPages({ commit }, newArray) {
      commit('replaceGuideChildren', newArray);
    },
    resetGuide({ commit }) {
      commit('resetGuideChildren');
    }

  },

  mutations: {    

    setVisibleHotspotGroups(state, newGroups) {
      state.VisibleHotspotGroups = newGroups;
    },    
    addVisibleHotspotGroup(state, newGroup) {
      state.VisibleHotspotGroups.push(newGroup);
    },    
    removeVisibleHotspotGroup(state, groupToRemove) {
      state.VisibleHotspotGroups = state.VisibleHotspotGroups.filter(group => group !== groupToRemove);
    },   
    resetVisibleHotspotGroups(state) {
      state.VisibleHotspotGroups = [];
    },
    setMinHotspotSizes(state, data) {
      state.minHotspotSize = data;      
    },
    setMaxHotspotSizes(state, data) {
      state.maxHotspotSize = data;
    },
    getCommentsSuccess(state, data) {
      state.mapComments = data;
    },
    getCommentsFailure(state) {
      state.mapComments = [];
    },
    addComment(state, data) {
      state.newComment = data;
    },
    saveComment(state) {
      if (state.newComment !== undefined)
        state.mapComments.push(state.newComment);
      state.newComment = undefined;
    },
    cancelComment(state) {
      state.newComment = undefined;
    },
    getEsriTokenSuccess(state, data) {
      state.esriTokens = data;
    },
    getEsriTokenFailure(state) {
      state.esriTokens = [];
    },
    getSiteSettingsSuccess(state, data) {
      state.siteSettings = data;
    },
    getSiteSettingsFailure(state) {
      state.siteSettings = {};
    },
    getSiteStructureSuccess(state, data) {
      state.siteStructure = data.items;
    },
    getSiteStructureFailure(state) {
      state.siteStructure = [];
    },
    getPageSuccess(state, data) {
      state.currentChildren = [];
      state.currentPage = data;
      if (state.currentPage.meta.type === "engage_virtualrooms.VirtualRoomPage")
        state.showHeader = false;
      else state.showHeader = true;
    },
    getPageFailure(state) {
      state.currentChildren = [];
      state.currentPage = undefined;
    },
    getPageChildrenSuccess(state, data) {
      state.currentChildren = data.items;
    },
    getPageChildrenFailure(state) {
      state.currentChildren = [];
    },

    getPopupSuccess(state, data) {
     state.currentPopup = data;
    },
    getPopupFailure(state) {
      state.currentPopup = undefined;
    },
    getStickySuccess(state, data) {
      state.stickyItem = data;
    },
    getStickyError(state) {
      state.stickyItem = undefined;
    },
    setPageTitle(state, data) {
      state.currentPage.title = data;
    },
    addKeyStat(state, data) {
      state.currentPage.key_stats.push(data);
    },
    removeKeyStat(state, index) {
      state.currentPage.key_stats.pop(index);
    },
    setMapTool(state, value) {
      state.activeTool = value;
    },
    showLegend(state, value) {
      state.showLegend = value;
    },
    setLayerFilter(state, value) {
      state.layerFilter = value;
    },
    setLayerOptions(state, value) {
      // If the new value is null, delete the entry
      if (value.data === null) {
        Vue.delete(state.layerOptions, value.key);
      } else {
        // Iterate through all existing entries in state.layerOptions
        Object.keys(state.layerOptions).forEach(existingKey => {
          // Iterate through keys in the new data
          Object.keys(value.data).forEach(newDataKey => {
            // If a match is found, update the value
            if (state.layerOptions[existingKey].hasOwnProperty(newDataKey)) {
              Vue.set(state.layerOptions[existingKey], newDataKey, value.data[newDataKey]);
            }
          });
        });
    
        // Additionally, set the new data in state.layerOptions
        Vue.set(state.layerOptions, value.key, value.data);
      }
    },    
    setAccessibiltyMode(state, value) {
      state.accessibilityMode = value;
    },
    setLandingStreamfield(state, value) {
      state.setLandingStreamfield = value;
    },
    setViewedPage(state, value) {
      state.pageViewed = value;
    },
    setGuideEnabled(state, payload) {
      state.roomGuide.enabled = payload;
    },
    stepGuideEnabled(state, payload) {
      state.roomGuide.currentStep = state.roomGuide.currentStep + 1;
    },    
    addGuideChild(state, pageId) {
      if (!state.roomGuide.childPageIds.includes(pageId)) {
        state.roomGuide.childPageIds.push(pageId);
      }
    },
    removeGuideChild(state, pageId) {
      state.roomGuide.childPageIds = state.roomGuide.childPageIds.filter(
        id => id !== pageId
      );
    },
    replaceGuideChildren(state, newArray) {
      state.roomGuide.childPageIds = [...newArray];
    },
    ResetGuideChildren(state) {
      state.roomGuide.childPageIds = [];
      state.roomGuide.currentStep = 0;
    }
  },
  getters: {
    getLayerOptions(state) {
      return state.layerOptions;
    },

    getMaxHotspotSize(state) {
      return state.maxHotspotSize;
    },
    getMinHotspotSize(state) {
      return state.minHotspotSize;
    },
    getVisibleHotspotGroups(state) {
      return state.VisibleHotspotGroups;
    },
  }
};
