import { render, staticRenderFns } from "./LightboxBase.vue?vue&type=template&id=3824b010&scoped=true&"
import script from "./LightboxBase.vue?vue&type=script&lang=js&"
export * from "./LightboxBase.vue?vue&type=script&lang=js&"
import style0 from "./LightboxBase.vue?vue&type=style&index=0&id=3824b010&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3824b010",
  null
  
)

export default component.exports